@font-face {
  font-family: 'PlutoSansDPD';
  font-weight: 100;
  src: local('PlutoSansLight'), url('./fonts/PlutoSansDPDLight.ttf');
}

@font-face {
  font-family: 'PlutoSansDPD';
  font-weight: 100;
  font-stretch: condensed;
  src: local('PlutoSansExtraLight'), url('./fonts/PlutoSansDPDExtraLight.ttf');
}

@font-face {
  font-family: 'PlutoSansDPD';
  font-stretch: condensed;
  src: local('PlutoSansThin'), url('./fonts/PlutoSansDPDThin.ttf');
}

@font-face {
  font-family: 'PlutoSansDPD';
  font-weight: normal;
  src: local('PlutoSansRegular'), url('./fonts/PlutoSansDPDRegular.ttf');
}
